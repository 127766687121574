<template>
  <div class="login">
    <div class="section-authentication">
      <div class="container">
        <Row type="flex" justify="center">
          <Col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
            <div class="content">
              <div class="headline">
                <h2 class="title">Prijava</h2>
              </div>
              <div class="form">
                <Form :model="formData" :rules="rules" ref="componentForm" @submit.native.prevent="validateForm('componentForm')">
                  <FormItem prop="email" label="E-mail">
                    <Input
                      v-model="formData.email"/>
                  </FormItem>
                  <FormItem prop="password" label="Lozinka">
                    <Input
                      v-model="formData.password"
                      show-password/>
                  </FormItem>
                  <FormItem>
                    <Button
                      native-type="submit"
                      class="btn-light"
                      :loading="loading">
                      Nastavite
                    </Button>
                  </FormItem>
                </Form>
              </div>
              <div class="password-recovery">
                <router-link :to="{ name: 'AuthenticationPasswordRecoveryIndex' }">Zaboravili ste lozinku?</router-link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';
import {Authentication} from '@/mixins/Authentication';

// Element UI
import {Row, Col, Form, FormItem, Input, Button} from 'element-ui';

// Logic
export default {
  name: 'AuthenticationLoginIndex',
  mixins: [Common, Authentication],
  components: {
    Row,
    Col,
    Form,
    FormItem,
    Input,
    Button
  },
  data() {
    return {
      formData: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Molimo upišite E-mail',
            trigger: ['blur']
          },
          {
            type: 'email',
            message: 'Molimo unesite ispravnu E-mail adresu',
            trigger: ['blur']
          }
        ],
        password: [
          {
            required: true,
            message: 'Molimo upišite lozinku',
            trigger: ['blur']
          }
        ]
      }
    }
  },
  methods: {
    validateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitForm(formName);
        } else {
          return false;
        }
      });
    },
    submitForm(formName) {
      this.setLoadingComponent(true);
      this.Axios.post('authentication/login', {
        email: this.formData.email,
        password: this.formData.password
      }).then(response => {
        this.$store.dispatch('setAccessToken', response.data.content.data.accessToken);
        this.Axios.defaults.headers.common['Authorization'] = this.$store.state.accessToken;
        this.message = response.data.content.message;
        this.status = response.data.content.status;
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
        }
      }).finally(() => {
        this.setLoadingComponent(false);
        this.setNotification();
        this.setData(formName);
        this.setAuthenticationRedirect();
      });
    },
    setData(formName) {
      this.formData = {
        email: '',
        password: ''
      };
      this.$refs[formName].resetFields();
    }
  },
  mounted() {
    this.initialize();
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';

.section-authentication {
  display: flex;
  align-items: center;
  min-height: 100vh;

  .content {
    .headline {
      text-align: center;

      .title {
        font-size: 28px;
        font-weight: 400;
        margin: 0;

        // Mobile
        @include media-breakpoint-down(sm) {
          font-size: 20px;
        }
      }
    }

    .form {
      margin: 24px auto 0;
    }

    .return,
    .password-recovery {
      text-align: center;
      margin: 24px 0 0;

      // Mobile
      @include media-breakpoint-down(sm) {
        margin: 16px 0 0;
      }

      a {
        font-size: 14px;
      }
    }
  }
}
</style>
